.home-container1{
    min-height: 100vh;
    max-width: 12500px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0% auto;
    background: rgb(23, 24, 28);
    color: white;   
}
.s{
    color: green;
    text-align: center;
    margin-bottom: 10px;
}
.light-theme .home-container1{
    background-color: whitesmoke;
    color: black;
}
.home-container2{
    margin-top: 24px;
    max-width: 1100px;
    width: calc(100% - 164px);
    border: 1px solid #4e4e4e;
    padding: 24px;
    box-sizing: border-box;
    margin-left: 10px;
}

.light-theme .home-container2{
    border: 1px solid #d6d9dc;
}

.pp{
    color: rgba(245, 245, 245, 0.89);
}

.light-theme .pp{
    color: black;
}


@media only screen and (max-width: 992px){
    .home-container1{
        flex-wrap: wrap;
    }
    .home-container2{
        width: 70%;
        margin-right: 10px;
        margin-left: -10px;
    }
}

@media only screen and (max-width: 768px){
    .home-container1{
        flex-wrap: wrap;
    }
    .home-container2{
        width: 70%;
        margin-right: 10px;
        margin-left: -10px;
    }
}

@media only screen and (max-width: 576px){
    .home-container1{
        flex-wrap: wrap;
    }
    .home-container2{
        width: 70%;
        margin-right: 10px;
        margin-left: -10px;
    }
}
