

.left-sidebsr{ 
    width: 320px;
    box-shadow: 1px 1px 0 rgba(0,0,0 0.2);
    transition: box-shadow ease-in-out 0.1s, transform ease-in-out 0.1s;
    transform: translateZ(0);
    box-sizing: border-box;
    background: rgb(23, 24, 28);
    color: white;
    font-size: 14px;
    margin-right: 0px;
}

.light-theme .left-sidebsr{
    background-color: whitesmoke;
    color: black;
}


.side-nav{
    height: auto;
    max-width: 100%;
    position: sticky;
    margin: 20px 0px;
    padding: 20px 0px;
    margin: 2px 2px 2px 2px;
    
}

.side-nav-div{
    padding: 10px 0px;
}
.side-nav-div div{
    /* 10px */
    padding-left: 10px;   
}
.site-nav-links{
    text-decoration: none;
    color: #3a3a3a;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
    transition: 0.2s;
    padding: 10px;
  
}
.sode-nav-links:hover{
    color: black;
}
.active{
    text-decoration: none;
    font-weight: bolder;
    /* color: black; */
    color: rgb(7, 235, 33);
    /* color: red; */
    font-size: 15.5px;
    /* padding: 3px 3px 3px 3px; */
    /* border-radius: 2px; */
    /* background-color: rgb(62, 230, 6); */
    /* border-right: solid 3px orange; */
}
.light-theme .active{
    color: rgb(26, 12, 234);
}
.activesub{
    text-decoration: none;
    font-weight: bolder;
    color: black;
    padding: 2px 6px 2px 6px;
    border-radius: 2px;
    /* background-color: rgb(222, 224, 224); */
    background-color: rgb(207, 206, 206);
    /* border-right: solid 3px orange; */
}
.active1{
    background-color: rgba(138, 148, 160, 0.559);
    padding: 10px;
    font-weight: bolder;
    border-right:3px solid green;
    margin: 2px 2px 2px 2px;
    /* color: black; */
}

@media only screen and (max-width: 992px){
    .left-sidebsr{
        width: 25%;
    }
}
@media only screen and (max-width: 768px){
    .left-sidebsr{
        width: 25%;
    }
}

@media only screen and (max-width: 576px){
    .left-sidebsr{
        width: 25%;
    }
}

