.right-sidebar{
    float: right;
    width: 380px;
    margin: 14px 5px 10px 20px;
    
    /* background: rgba(6, 11, 26, 1); */
}

.widget{
    margin-top: 10px;
    box-shadow: 3px 3px 10px rgb(0 0 0 5%), -3px -3px -3px 10px rgb(0 0 0 5%);
}
.widget h4{
    /* background-color: #fbf3d5; */
    background: rgb(23, 24, 28);
    
    margin: 0px;
    padding: 15px;
    border: solid 1px #a0abb2; 
}

.light-theme .widget h4{
    background-color: whitesmoke;
}

.right-sidebar-div-1{
    background: rgb(23, 24, 28);
    padding: 15px;
    border: solid 1px #a0abb2;
}

.light-theme .right-sidebar-div-1{
    background-color: whitesmoke;
}
.right-sidebar-div-1 .right-sidebar-div-2{
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
}
.right-sidebar-div-1 .right-sidebar-div-2 p{
    padding-left: 10px;
    margin-top: 0px;
}
.widget-tags{
    margin-top: 40px;
    box-shadow: 3px 3px 10px rgb(0 0 0 5%), -3px -3px -3px 10px rgb(0 0 0 5%);
}
.widget-tags h3{
    margin: 0px;
    padding: 15px;
    background: rgb(23, 24, 28);
    border: solid 1px #a0abb2;
}

.light-theme .widget-tags h3{
    background-color: whitesmoke;
}
.widget-tags-div{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-evenly;
    /* border: solid 1px #e3e6e8; */
    border: solid 1px #a0abb2;
    padding: 10px;   
}

.widget-tags-div{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-evenly;
    border: solid 1px #e3e6e8;
    padding: 10px;
}
.widget-tags-div p{
    padding: 5px;
    background-color: #E1ECF4;
    color: #39739d;
    border-radius: 2px;
    font-size: 14px;
}

.iconrr{
    background: #e1ecf4a6;
    padding: 3px 4px 3px 4px;
    border-radius: 3px;
}

.pp{
    color: rgba(245, 245, 245, 0.734);
}

.light-theme .pp{
    color: black;
}

.tag{
    margin-bottom: 3px;
}

@media only screen and (max-width: 992px){
    .right-sidebar{
        width: 100%;
    }
}
@media only screen and (max-width: 768px){
    .right-sidebar{
        width: 100%;
    }
}
@media only screen and (max-width: 576px){
    .right-sidebar{
        width: 100%;
    }
}