.home-container1{
    min-height: 100vh;
    max-width: 12500px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0% auto;
    background: rgb(23, 24, 28);
    color: white;   
}
.s{
    color: green;
    text-align: center;
    margin-bottom: 10px;
}
.light-theme .home-container1{
    background-color: whitesmoke;
    color: black;
}
.home-container2{
    margin-top: 24px;
    max-width: 1100px;
    width: calc(100% - 164px);
    border: 1px solid #4e4e4e;
    padding: 24px;
    box-sizing: border-box;
    margin-left: 10px;
}

.light-theme .home-container2{
    border: 1px solid #d6d9dc;
}

.pp{
    color: rgba(245, 245, 245, 0.89);
}

.light-theme .pp{
    color: black;
}

.ppp{
    background: #4e4e4e7e;
    padding: 10px 10px 10px 15px;
    border-radius: 8px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.nn{
    background: rgba(0, 128, 0, 0.58);
    padding: 10px 10px 10px 15px;
    border-radius: 8px;
    margin-top: 10px;
    margin-bottom: 10px;
}

@media only screen and (max-width: 992px){
    .home-container1{
        flex-wrap: wrap;
    }
    .home-container2{
        width: 70%;
        margin-right: 10px;
        margin-left: -10px;
    }
    h2{
        font-size: 25px;
    }
}

@media only screen and (max-width: 768px){
    .home-container1{
        flex-wrap: wrap;
    }
    .home-container2{
        width: 70%;
        margin-right: 10px;
        margin-left: -10px;
    }
    h2{
        font-size: 20px;
    }
}

@media only screen and (max-width: 576px){
    .home-container1{
        flex-wrap: wrap;
    }
    .home-container2{
        width: 70%;
        margin-right: 10px;
        margin-left: -10px;
    }
    h2{
        font-size: 25px;
    }
}




table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 20px;
  }
  
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  /* tr:nth-child(even) {
    background-color: #322c2c;
  } */

  .sort{
    background: #6261618b;
    color: white;
  }

  .container-tab{
    width: auto;
    height: auto;
  }

  .block-tabs{
    display: flex;
  }
  .tabs:not(:last-child){
    border-right: 1px solid rgba(0, 0, 0, 0.274);
  }

  .content-tabs{
    background: black;
    /* border-bottom: 1px solid transparent; */
    color: #222;
  }

  .active-tabs{
    background: wheat;
    color: black;
    border-bottom: 3px solid green;
    border-radius: 15px;
  }

  .content-tabs::before{
    content: "";
    display: block;
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 2px);
    height: 5px;
    background-color: rgb(88, 147, 241);
  }

  button{
    border: none;
  }

  .content-tabs{
    flex-grow: 1;
  }

  .content{
    background:black;
    padding: 20px;
    width: 100%;
    height: 100%;
    display: none;
    color: white;
  }

  .content h2{
    padding: 0px 0px 5px 0px;
  }

  .content hr{
    width: 100px;
    height: 2px;
    background: #222;
    margin-bottom: 5px;
  }

  .content p{
    width: 100%;
    height: 100%;
  }
.active-content{
    display: block;
}
