
.header{
    width: 100%;
    height: 80px;
    line-height: 80px;
    /* background: #0c123d; */
    /* background-color:rgb(5, 56, 133); */
    /* background: #0c123d; */
    /* background: green; */
}

.nav_wrappe{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.menu{
    display: flex;
    align-items: center;
    column-gap: 2.7rem;
    list-style: none;
}

.menu__link{
    font-weight: 400;
    font-size: .9rem;
    cursor: pointer;
}

.menu__link:hover{
    color: var(--link-active);
}

.logo h2{
    color: var(--primary-color);
    cursor: pointer;
}

.light__mode span{
    color: #ffffff99;
    display: flex;
    align-items: center;
    column-gap: 0.4rem;
    font-size: 0.8rem;
    cursor: pointer;
}
.light__theme, .light__mode span{
    color: var(--primary-color);
    font-weight: 500;
    font-size: 18px;
}

.header__shrink{
    width: 100%;
    height: 80px;
    line-height: 80px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 9999;  
    background: #0c123d;
}
.light-theme .header__shrink{
    background: whitesmoke;
    box-shadow: 2px 2px 10px -2px #ddd;
}

.light-theme .header{
    background-color: rgb(212, 212, 214);
    box-shadow: #0c123d;
}

.mobile__menu{
    color: #ffffff;
    font-size: 1.3rem;
    cursor: pointer;
    display: none;
}

.light-theme .mobile__menu{
    color: #0c123d;
}


.navactive{
    /* text-decoration: none; */
    font-weight: bolder;
    color: rgb(14, 107, 238);
}

.logo1{
    box-shadow: 3px 2px 14px 5px grey;
    margin-right: 10px;

}


/* ============ Responshie ============== */

@media only screen and (max-width: 992px) {
    .navigation{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(6, 11, 26, 0.868);
        z-index: 99999;
        display: none;
    }
    /* esase menu center me aata hai and all */
    .menu{
        flex-direction: column;
        /* background: #0c123d; */
        background:rgba(30, 110, 171, 0.895);
        width: 180px;
        height: 100%;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 999999;
        padding-top: 25px;
        padding-bottom: 20px;
    }
    .menu__active{
        display: block;
    }
    .mobile__menu{
        display: block;
    }
}

@media only screen and (max-width:768px) {
    .header{
        width: 100%;
        height: 80px;
    }
    .mobile__menu{
        font-size: 1rem;
    }
    .logo h2{
        font-size: 1.3rem;
    }
    .menu__link{
        margin-bottom: -10px;
    }
}