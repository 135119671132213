.containerp{
  border-radius: 0px;
}

.icon{
  width: 1.8rem;
  cursor: pointer;
  background: none;
  color: #e5e5e5;
}

/* Post artical */
article{
    margin: 4rem 0;
    /* background-color: rgb(225, 214, 214);
    border-radius: 10px;
    padding: 10px 10px 10px 10px; */
  }
  
.post-wrapper{
  width: 100%;  
  margin-top: -50px;
}
  
  /* Code */
  .code{
    position: relative;
  }
  .code .copy-icon{
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 5;
  }
